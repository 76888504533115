import React, { useState, useEffect } from "react";
import "./customMarquee.css"; // Importa tu archivo de estilos CSS para la marquesina

const CustomMarquee = ({ elements, speed }) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    // Función para actualizar la posición de la marquesina
    const updatePosition = () => {
      setPosition((prevPosition) => prevPosition + 1);
    };

    // Configura un temporizador para actualizar la posición a la velocidad especificada
    const timer = setInterval(updatePosition, speed);

    // Limpia el temporizador cuando el componente se desmonta
    return () => {
      clearInterval(timer);
    };
  }, [speed]);

  return (
    <div className="custom-marquee">
      <div className="marquee-content">
        {/* Mapea los elementos y crea un bucle de marquesina */}
        {elements.map((element, index) => (
          <div key={index} className="marquee-element">
            {element}
          </div>
        ))}
      </div>
      {/* Aplica un estilo de transformación CSS para desplazar los elementos */}
      <style>
        {`
          .marquee-content {
            transform: translateX(-${position}px);
          }
        `}
      </style>
    </div>
  );
};

export default CustomMarquee;
