import homeCover from '../../assets/images/svg/homeCover.svg';
import workHome  from '../../assets/images/svg/workHome.svg';
import { Image } from 'antd';
import React from 'react';
import html5 from "../../assets/images/icons/html5.png";
import css3 from "../../assets/images/icons/css3.png";
import JavaScript from "../../assets/images/icons/JavaScript.png";
import typescript from "../../assets/images/icons/typescript.png";
import reactjs from "../../assets/images/icons/reactjs.png";
import nodejs from "../../assets/images/icons/nodejs.png";
import net from "../../assets/images/icons/net.png";
import c from "../../assets/images/icons/c.png";
import msqlserver from "../../assets/images/icons/msqlserver.png";
import mysql from "../../assets/images/icons/mysql.png";
import mongodb from "../../assets/images/icons/mongodb.png";
import aws from "../../assets/images/icons/aws.png";
import azure from "../../assets/images/icons/azure.png";


export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Information Technology Company",
  headline: "Coney's Technologies S.R.L",
  description:
    "Aliado tecnológico enfocado en el sector financiero, que otorga servicios de desarrollo de software, infraestructura, soporte y telecomunicaciones.",
  buttonLabel: "Contáctanos",
  imgStart: "",
  img: homeCover,
  alt: "Coney Technologies",
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Coney Technologies',
  headline: 'Cómo trabajamos',
  description:
    "Nuestras metodología de trabajo es ágil utilizamos estrategias y tecnología a la vanguardia para brindarte el mejor servicio.",
  imgStart: 'start',
  img: workHome,
  alt: 'Coney Technologies',
};

export const homeObjThree = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Coney Technologies',
  headline: 'Las tecnologías más utilizadas',
  description:
    "Nuestro equipo se especializa en las mejores herramientas tecnológicas disponibles, con el objetivo de ofrecer soluciones óptimas a sus necesidades.",
  imgStart: 'start',
  marqueeElements: [
    <Image key="1" src={html5} alt="HTML5" />,
    <Image key="2" src={css3} alt="CSS3" />,
    <Image key="3" src={JavaScript} alt="JavaScript" />,
    <Image key="4" src={typescript} alt="TypeScript" />,
    <Image key="5" src={reactjs} alt="React.js" />,
    <Image key="6" src={nodejs} alt="Node.js" />,
    <Image key="7" src={net} alt=".NET" />,
    <Image key="8" src={c} alt="C#" />,
    <Image key="9" src={msqlserver} alt="SQLServer" />,
    <Image key="10" src={mysql} alt="MySQL" />,
    <Image key="11" src={mongodb} alt="MongoDB" />,
    <Image key="12" src={aws} alt="AWS" />,
    <Image key="13" src={azure} alt="Azure" />,

  ],
  alt: 'Coney Technologies',
};
