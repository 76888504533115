import productCover from '../../assets/images/svg/productCover.svg';

export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  // topLine: "Information Technology Company",
  headline: "Productos",
  description:
    "Contamos con productos de servicios de nuestra propiedad intelectual a la vanguardias con los estandares de las alta tecnologias.",
  imgStart: "",
  img: productCover,
  alt: "Coney Technologies",
};
