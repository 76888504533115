import React from "react";
import Section from "../../components/Section/Section";
import {homeObjOne} from "./Data";

function Blog() {
  return (
    <>
       <Section {...homeObjOne} />
    </>
  );
}

export default Blog;
