import React from "react";
import SectionCover from "../../components/Section/SectionCover";
import {homeObjOne} from "./Data";

function Products() {
  return (
    <>
      <SectionCover {...homeObjOne} />
    </>
  );
}

export default Products;
