import contactCover from '../../assets/images/svg/contactCover.svg';

export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Obtenga Información Rápida",
  headline: "Contáctanos",
  description:
    "Tus dudas son importantes para nosotros, si deseas más información, utiliza los medios de contacto sugeridos para apoyarte con tu solución.",
  buttonLabel: "Contáctanos",
  imgStart: "",
  img: contactCover,
  alt: "Coney Technologies",
};
