import React from "react";
import SectionCover from "../../components/Section/SectionCover";
import {homeObjOne} from "./Data";
import CustomContact from "../../components/CustomForms/CustomContact/CustomContact";
import "./contact.css"

function Contact() {
  return (
    <>
        <SectionCover {...homeObjOne} />
        <div className="custom-contact">
        <CustomContact />
      </div>
    </>
  );
}

export default Contact;
