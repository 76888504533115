import blogCover from '../../assets/images/svg/blogCover.svg';

export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Blog",
  headline: "Preguntas frecuentes",
  description:
    "Comience su día con Coney Technologies Blog.",
  buttonLabel: "Artículos",
  imgStart: "",
  img: blogCover,
  alt: "Coney Technologies",
};
