import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import coneyContactLogo from "../../assets/images/coney-contact.png"; // Importa el logotipo


function Footer() {
  const instagramUrl = "https://www.instagram.com/coneytechnologies/";
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Servicios</h2>
            <Link to="/services">Desarrollo de APIs</Link>
            <Link to="/services">Integración de sistemas</Link>
            <Link to="/services">Computación en la nube</Link>
            <Link to="/services">Desarrollo de software a la medidae</Link>
            <Link to="/services">Desarrollo de aplicaciones Web</Link>
            <Link to="/services">Desarrollo de aplicaciones móviles</Link>
            <Link to="/services">Automatización de procesos</Link>
          </div>
          <div className="footer-link-items">
            <h2>Productos</h2>
            <Link to="/products">Proyectos</Link>
            <Link to="/products">Soportes</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Blog</h2>
            <Link to="/blog">Articulos</Link>
          </div>
          <div className="footer-link-items">
            <h2>Contacto</h2>
            <Link to="/contact">Contactos
            <img
              className="footer-navbar-icon"
              src={coneyContactLogo}
              alt="Coney Technologies"
            />
            </Link>
            <h2>Encuentranos</h2>
            <a
              className="social-icon-link"
              href={instagramUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <img src="./Logotipo.png" alt="Coney Technologies" />
            </Link>
          </div>
          <small className="website-rights">Coney Technologies © 2023</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
