import React from "react";
import Section from "../../components/Section/Section";
import { homeObjOne, homeObjTwo, homeObjThree } from "./Data";
import SectionCover from "../../components/Section/SectionCover";
import CustomSection from "../../components/Section/CustomSection";

function Home() {
  return (
    <>
      <Section {...homeObjOne} />
      <SectionCover {...homeObjTwo} />
      <CustomSection
        lightBg={homeObjThree.lightBg}
        topLine={homeObjThree.topLine}
        lightText={homeObjThree.lightText}
        lightTextDesc={homeObjThree.lightTextDesc}
        headline={homeObjThree.headline}
        description={homeObjThree.description}
        marqueeElements={homeObjThree.marqueeElements} // Pasa los elementos desde homeObjThree
        imgStart={homeObjThree.imgStart}
      />
    </>
  );
}

export default Home;
