import servicesCover from "../../assets/images/svg/servicesCover.svg";
import api from "../../assets/images/icons/api.png";
import integracion from "../../assets/images/icons/integracion.png";
import cloud from "../../assets/images/icons/cloud.png";
import software from "../../assets/images/icons/software.png";
import mobile from "../../assets/images/icons/mobile.png";
import web from "../../assets/images/icons/web.png";

export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  // topLine: "Information Technology Company",
  headline: "Catálogo de Servicios",
  description:
    "Líderes en innovación al ofrecer servicios de desarrollo de software bajo demanda.",
  // buttonLabel: "Contáctanos",
  imgStart: "",
  img: servicesCover,
  alt: "Coney Technologies",
};

// data.js

export const cardDataServices = [
  {
    title: "Desarrollo de APIs",
    description:
      "Diseño e integración de APIs empresariales, con altas tecnologias.",
    imageSrc: api, // Usar el icono aquí
  },
  {
    title: "Integración de sistemas",
    description:
      "Diseñando soluciones con integraciones a servicios o APIs de terceros.",
    imageSrc: integracion,
  },
  {
    title: "Computación en la nube",
    description:
      "Nuestro servicio de computación en la nube permite a los equipos de TI mejorar el rendimiento de las aplicaciones",
    imageSrc: cloud,
  },
  {
    title: "Desarrollo de software",
    description:
      "Diseñamos software que se alinee con sus procesos y flujos de trabajo.",
    imageSrc: software,
  },
  {
    title: "Desarrollo App móviles",
    description:
      "Desarrtollo de aplicaciones multiplataformas y nativas (iOS, Android e Híbridos)",
    imageSrc: mobile,
  },
  {
    title: "Desarrollo App Web ",
    description:
      "Nuestro servicios esta especializado en la creación de soluciones web de nivel empresarial.",
    imageSrc: web,
  },
];
