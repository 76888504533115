import React from "react";
import { Card  } from "antd";
import "./customCard.css";
const { Meta } = Card;

const CustomCard = ({ title, description, imageSrc }) => {
  return (
    <div className="card-container">
    <Card hoverable className="custom-card">
    {typeof imageSrc === 'function' ? (
          imageSrc() // Renderiza el icono llamando a la función
        ) : (
          <img alt={title} src={imageSrc} />
        )}
      <Meta title={title} description={description} />
    </Card>
  </div>
  );
};

export default CustomCard;
