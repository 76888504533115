import React from "react";
import SectionCover from "../../components/Section/SectionCover";
import { homeObjOne, cardDataServices } from "./Data";
import CustomCard from "../../components/Card/CustomCard"; // Asegúrate de ajustar la ruta correcta a tu componente CustomCard
import "./services.css";

function Services() {
  return (
    <>
      <SectionCover {...homeObjOne} />
      <div className="card-container">
        {cardDataServices.map((card, index) => (
          <CustomCard
            key={index}
            title={card.title}
            description={card.description}
            imageSrc={card.imageSrc}
          />
        ))}
      </div>
    </>
  );
}

export default Services;
