// CustomContact.js
import React from "react";
import "./customContact.css"; // Añade estilos personalizados aquí si es necesario

const CustomContact = () => {
  return (
    <div className="contact-form">
      <h2>Contacto</h2>
      {/* Agrega tus campos de formulario aquí */}
      <form>
        <div className="form-group">
          <input type="text" id="name" name="name" placeholder="Nombre" required />
        </div>
        <div className="form-group">
          <input type="text" id="phone" name="phone" placeholder="Teléfono" required />
        </div>
        <div className="form-group">
          <input type="email" id="email" name="email" placeholder="Email" required />
        </div>
        <div className="form-group">
          <textarea id="message" name="message" rows="4" placeholder="Escriba su mensaje aqui" required />
        </div>
        <button type="submit" className="center-button">Enviar mail</button>
      </form>
    </div>
  );
};

export default CustomContact;
