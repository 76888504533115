import React from "react";
// import { css } from "@emotion/react";
// import { CircleLoader } from "react-spinners";

import "./progressBar.css";
// import { Spin } from "antd";
import logo from '../../assets/images/Logotipo.png';

const ProgressBar = ({ color }) => {
  return (
    <div className="progress-bar-container">
      <img
        src={logo}
        alt="Coney Technologies"
        className="spin-logo"
        style={{ borderColor: color }}
      />
    </div>
  );
};

export default ProgressBar;
